import { message } from "antd";
import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";

import { Alert, DEPRECATED_Button, CoeLogo, LoaderWithPerfTimings } from "../../../components";
import { COLORS } from "../../../constants";
import { BContext } from "../../../types";
import { useTrack } from "../../../utils";
import { BillingContext } from "../Billing";
import { BillingForm } from "../components";

const Wrap = styled.div`
  width: 480px;
  margin: auto;
  color: ${COLORS.black85};

  .sectionTitle {
    font-weight: bold;
    margin: 10px auto 20px;
  }

  .updateBtn {
    width: 100%;
    margin: 32px auto;
  }
`;

const CoeLogoSmall = styled(CoeLogo)`
  width: 120px;
  margin: 10px 0 18px 0;
`;

const StyledAlert = styled(Alert)`
  border: none;
  margin-bottom: 16px;
`;

export const UpdatePayment = () => {
  const track = useTrack();

  const {
    userSubscription,
    updateSubscription,
    getUserSubscription,
    billingMetadata,
    usersInCurrentPlan,
    setBillingInterval,
    setPlanType,
  } = useContext(BillingContext) as BContext;

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [formError, setFormError] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [country, setCountry] = useState<string>("US");
  const [zip, setZip] = useState<string>("");
  const [updatingCard, setUpdatingCard] = useState<boolean>(false);

  const card: any = useRef();
  const stripe: any = useRef();

  const subscriptionDetails = userSubscription?.subscription_details;
  const planType = subscriptionDetails?.plan_type || "";

  const currentPlan = billingMetadata?.plans.find(
    plan =>
      plan.plan_type === planType && plan.billing_interval === subscriptionDetails?.billing_interval
  );

  const resetPaymentForm = () => {
    setErrorMsg("");
    setFormError(false);
    setName("");
    setCountry("US");
    setZip("");
  };

  const updatePaymentInfo = async () => {
    setErrorMsg("");
    if (!name || !country || !zip) {
      return setFormError(true);
    }
    setUpdatingCard(true);
    const result = await stripe.current.createToken(card.current, {
      name,
      address_country: country,
      address_zip: zip,
    });

    if (result.error) {
      setErrorMsg(result.error.message);
    } else {
      const response = await updateSubscriptionOnServer({
        plan: currentPlan,
        update_type: "change_payment_info",
        selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id),
        selected_data_source_types: subscriptionDetails?.selected_data_source_types,
        stripe_card_token: result.token.id,
        subscription_id: userSubscription?.subscription_details?.subscription_id,
      });

      resetPaymentForm();
      track(response ? "billing_payment_update_failed" : "billing_payment_update_succeeded", {
        selected_num_data_sources: subscriptionDetails?.selected_data_source_types?.length,
        selected_num_users: usersInCurrentPlan.length,
        selected_frequency: currentPlan?.billing_interval,
        selected_plan_type: currentPlan?.plan_type,
      });
    }

    setUpdatingCard(false);
  };

  const updateSubscriptionOnServer = async body => {
    const response = await updateSubscription(body);
    if (!response.is_success) {
      const errorMsg = response.error_msg || "Uh-oh. Something went wrong. Please try again";
      message.error(errorMsg);
      return errorMsg;
    } else {
      message.success("Payment details updated successfully");
    }
    return;
  };

  return (
    <Wrap>
      <CoeLogoSmall />

      <h2 className="sectionTitle">Update payment details</h2>

      {errorMsg && (
        <StyledAlert
          type="error"
          showIcon={true}
          message={
            <>
              <strong>Error processing payment.</strong> {errorMsg}
            </>
          }
        />
      )}
      <BillingForm
        card={card}
        stripe={stripe}
        formError={formError}
        name={name}
        setName={setName}
        country={country}
        setCountry={setCountry}
        zip={zip}
        setZip={setZip}
      />

      <DEPRECATED_Button
        key="submit"
        type="primary"
        size="large"
        onClick={updatePaymentInfo}
        disabled={updatingCard}
        className="updateBtn"
      >
        {updatingCard ? (
          <LoaderWithPerfTimings name="UpdatePayment.button" color="white" />
        ) : (
          "Update"
        )}
      </DEPRECATED_Button>
    </Wrap>
  );
};
