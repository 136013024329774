import { SubscriptionPlanType } from "@coeff/api";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { Alert, Button, FlexColumn, FlexRow, PlanTag, Typography } from "../../components";
import { COLORS } from "../../constants";
import { BContext } from "../../types";
import { capitalize } from "../../utils";

import { BillingContext } from "./Billing";
import { HelpContent } from "./components/HelpContent";

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

const StyledCard = styled.div<{ background?: string }>`
  margin-bottom: 16px;
  border: none;
  padding: 12px 16px;
  min-height: 88px;
  background: ${props => (props.background ? props.background : COLORS.white)};
  display: flex;
  border-radius: 6px;
`;

const StyledPlanTag = styled.div`
  position: relative;
  left: -10px;
`;

const Layout = ({ children }) => (
  <Content>
    <Container>{children}</Container>
    <HelpContent />
  </Content>
);

const AlertCard = ({ title, secondaryTitle, tertiaryTitle, action }) => (
  <StyledCard background={COLORS.grayBg}>
    <FlexRow style={{ justifyContent: "space-between", flex: 1 }}>
      <FlexColumn style={{ flex: 1 }} gap={0}>
        <Typography fontWeight={600}>{title}</Typography>
        <Typography color="textSecondary">{secondaryTitle}</Typography>
        {tertiaryTitle && <Typography color="textSecondary">{tertiaryTitle}</Typography>}
      </FlexColumn>
      {action}
    </FlexRow>
  </StyledCard>
);

const SubscriptionCard: React.FC<{
  plan: string;
  licenses: number;
  since: string;
  type: SubscriptionPlanType;
  subscriptionId?: string;
  isManager?: boolean;
}> = ({ plan, licenses, since, type, subscriptionId, isManager = true }) => {
  const history = useHistory();
  const { basePath } = useContext(BillingContext) as BContext;
  return (
    <StyledCard background={COLORS.coeblue10}>
      <FlexRow style={{ justifyContent: "space-between", flex: 1 }}>
        <FlexColumn style={{ flex: 1 }} gap={0}>
          <Typography fontWeight={600}>{plan}</Typography>
          <Typography color="textSecondary">{`${licenses} user license(s) on this plan`}</Typography>
          <Typography color="textSecondary" fontSize="12px">{`Since ${since}`}</Typography>
        </FlexColumn>

        <FlexRow style={{ alignItems: "center", justifyContent: "space-between", flex: 1 }}>
          <StyledPlanTag>
            <PlanTag planType={type} />
          </StyledPlanTag>
          {subscriptionId && (
            <Button
              type="link"
              onClick={() => {
                if (isManager) {
                  history.push(`${basePath}/subscription?subscription_id=${subscriptionId}`);
                } else {
                  history.push(`${basePath}/team/manage?subscription_id=${subscriptionId}`);
                }
              }}
            >
              Manage
            </Button>
          )}
        </FlexRow>
      </FlexRow>
    </StyledCard>
  );
};

export const Subscriptions = () => {
  const { subscriptions, basePath, userSubscription, isFreeUser, isManager } = useContext(
    BillingContext
  ) as BContext;

  const history = useHistory();

  const { user_subscription, other_subscriptions } = subscriptions || {};

  const hasOtherSubscriptions = !!other_subscriptions?.length;

  useEffect(() => {
    document.title = "Subscriptions - Coefficient";

    if (!userSubscription && !other_subscriptions?.length) {
      history.push({
        pathname: `${basePath}/plans`,
        search: window.location.search,
      });
      return;
    }

    if (!other_subscriptions?.length) {
      const redirectPath = !isManager ? `${basePath}/team/manage` : `${basePath}/subscription`;
      history.push(
        `${redirectPath}?subscription_id=${user_subscription?.user_subscription?.subscription_id}`
      );
    }
  }, []);

  return (
    <Layout>
      <FlexColumn>
        <Section>
          <Typography fontSize="26px" fontWeight={"bold"}>
            Subscriptions
          </Typography>

          <Typography fontSize="16px" color="textSecondary" style={{ marginBottom: "36px" }}>
            Your workspace has multiple subscriptions
          </Typography>

          <Typography fontWeight={"bold"} fontSize="18px" style={{ marginBottom: "8px" }}>
            Your subscription plan
          </Typography>

          {isFreeUser && (
            <AlertCard
              title="You’re on the Free plan"
              secondaryTitle="Add yourself to a plan you manage below to get the most out of Coefficient."
              tertiaryTitle={undefined}
              action={undefined}
            />
          )}

          {user_subscription && (
            <SubscriptionCard
              plan={`${capitalize(user_subscription.user_subscription?.plan_type)} plan`}
              licenses={user_subscription.user_subscription?.max_users || 0}
              since={moment(
                user_subscription.user_subscription?.subscription_active_since_dt
              ).format("MMM DD, YYYY")}
              type={user_subscription.user_subscription!.plan_type}
              subscriptionId={user_subscription.user_subscription?.subscription_id}
              isManager={user_subscription.is_plan_manager}
            />
          )}
        </Section>

        {hasOtherSubscriptions && (
          <Section>
            <Typography fontWeight={"bold"} fontSize="18px" style={{ marginBottom: "8px" }}>
              Other subscription plans
            </Typography>

            {other_subscriptions.map(subscription => (
              <SubscriptionCard
                plan={`${capitalize(subscription.plan_type)} plan`}
                licenses={subscription?.max_users || 0}
                since={moment(subscription?.subscription_active_since_dt).format("MMM DD, YYYY")}
                type={subscription.plan_type}
                subscriptionId={subscription.subscription_id}
              />
            ))}
          </Section>
        )}
      </FlexColumn>
    </Layout>
  );
};
