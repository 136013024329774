import clsx from "clsx";
import React, { useState } from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

import { Tooltip } from "./Tooltip";

const StyledTagsInput = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 32px;
  width: 100%;
  padding: 6px;
  border: 2px solid ${COLORS.black15};
  border-radius: 6px;
  background: ${COLORS.white};

  &:focus-within {
    border-color: ${COLORS.coeblue4};
  }

  &.error {
    border-color: ${COLORS.red}!important;
  }

  input {
    flex: 1;
    border: none;
    font-size: 14px;
    padding: 0;

    ::placeholder,
    ::-webkit-input-placeholder {
      opacity: 0.4;
    }

    &:focus {
      outline: transparent;
    }

    &.padded {
      padding-top: 5px;
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;

    .tag {
      width: auto;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${COLORS.black85};
      padding: 4px 8px;
      font-size: 14px;
      list-style: none;
      border-radius: 6px;
      margin: 0 4px 4px 0;
      background: ${COLORS.coeblue};

      .tag-title {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .tag-close-icon {
        display: block;
        width: 8px;
        text-align: center;
        font-size: 18px;
        margin-left: 8px;
        color: ${COLORS.black85};
        border-radius: 50%;
        cursor: pointer;
      }

      &.tag-error {
        background: ${COLORS.redBg};

        .tag-title {
          text-overflow: ellipsis;
          overflow: hidden;
          color: ${COLORS.red};
        }
      }
    }
  }
`;

const ErrorText = styled.span`
  color: ${COLORS.red};
  font-weight: 500;
  margin-left: 5px;
`;

type Props = {
  error?: boolean;
  placeholder?: string;
  tags?: string[];
  max?: number;
  maxErrorText?: string;
  onChange?: (tags: Array<string>) => void;
  validator?: (str: string) => string | undefined;
};

const splitTextByRegex = (str: string, regex: RegExp): string[] => str.split(regex);

export const TagsInput: React.FC<Props> = ({
  validator,
  max,
  tags: _tags,
  placeholder,
  maxErrorText,
  onChange,
}) => {
  const [tags, setTags] = useState<string[]>(_tags || []);

  const [typedText, setTypedText] = useState<string>("");

  const [errorText, setErrorText] = useState<string>("");

  const [tagErrors, setTagErrors] = useState<Map<string, string>>(new Map());

  const isValid = (text: string) => {
    return validator ? validator(text) === undefined : true;
  };

  const removeTag = (indexToRemove: number) => {
    const newTags = [...tags.filter((_, index) => index !== indexToRemove)];

    setTags(newTags);

    const validatedInputs = newTags.filter(isValid);

    if (validatedInputs.length === newTags.length) {
      setErrorText("");
    }

    onChange?.(newTags);
  };

  const addTags = () => {
    const inputs = splitTextByRegex(typedText, /[, \n\t;]+/);

    const tagErrors: Map<string, string> = new Map();

    let newTags = Array.from(new Set([...tags, ...inputs]))
      .map(tag => tag.trim())
      .filter(tag => tag.length);

    if (max && newTags.length > max) {
      if (maxErrorText) {
        setErrorText(maxErrorText);
      } else {
        setErrorText(`You can only send invitations to ${max} users at a time.`);
      }

      newTags = newTags.slice(0, max);
    }

    const validatedInputs = newTags.filter(isValid);

    if (validatedInputs.length !== newTags.length) {
      setErrorText(
        "One or more emails you entered are invalid. Hover over each invalid email for more details."
      );
    }

    newTags.forEach(tag => {
      const error = validator?.(tag);

      if (error) {
        tagErrors.set(tag, error);
      }
    });

    setTagErrors(tagErrors);

    setTags(newTags);

    setTypedText("");

    onChange?.(newTags);
  };

  return (
    <>
      <StyledTagsInput
        id="tags-input-container"
        className={clsx("tags-input-container", {
          error: errorText !== "",
        })}
      >
        <ul className="coeff__taginput tags">
          {tags.map((tag, index) => (
            <Tooltip title={tagErrors.get(tag) ? tagErrors?.get(tag) : tag}>
              <li
                key={index}
                className={clsx("tag", {
                  "tag-error": tagErrors?.get(tag),
                })}
              >
                <span className="tag-title">{tag}</span>

                <span className="tag-close-icon" onClick={() => removeTag(index)}>
                  &times;
                </span>
              </li>
            </Tooltip>
          ))}

          <input
            type="text"
            autoFocus
            onKeyDown={event => {
              if (event.key === "Enter" || event.key === "," || event.key === " ") {
                event.preventDefault();
                event.stopPropagation();
                addTags();
              }
            }}
            placeholder={placeholder}
            onChange={e => setTypedText(e.currentTarget.value.trim())}
            value={typedText}
            onBlur={addTags}
            className={tags.length > 0 ? "padded" : ""}
          />
        </ul>
      </StyledTagsInput>

      {errorText && <ErrorText>{errorText}</ErrorText>}
    </>
  );
};
