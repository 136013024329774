import { CheckOutlined, UserOutlined } from "@ant-design/icons";
import {
  SubscriptionPlanType,
  SubscriptionUpdateType,
  SubscriptionUser,
  UpdateSubscriptions200Response,
  UserSubscriptionInfo,
} from "@coeff/api";
import { useMutation } from "@tanstack/react-query";
import { Dropdown, Menu } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  AddSeats,
  Breadcrumb,
  BreadcrumbItem,
  ConfirmBox,
  UserList,
  Typography,
  FlexColumn,
  FlexRow,
  Button,
  EllipsisMenu,
  Tooltip,
  message,
} from "../../../components";
import { AVATAR_COLORS as avatarColors, COLORS } from "../../../constants";
import { BContext } from "../../../types";
import {
  capitalize,
  getActiveOtherUsersInDomain,
  getIsGmailLikeDomainFromEmail,
  getPricingSummary,
  planNameFromType,
  pluralize,
  sentryCapture,
  useTrack,
} from "../../../utils";
import { BillingContext } from "../Billing";
import { PriceSummaryProratedV2 } from "../components";
import { AddUserToPlanModal } from "../components/AddUserToPlanModal";
import { HelpContent } from "../components/HelpContent";
import { InviteUserToPlanModal } from "../components/InviteUserToPlanModal";

const Container = styled.div`
  color: ${COLORS.black85};
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

const TeamInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 60%;

  .ant-progress-inner {
    background: ${COLORS.black15};
  }
`;

const PricingSummaryWrap = styled.div`
  background: ${COLORS.coeblue11};
  padding: 8px 24px;
  border-radius: 8px;
  margin: auto;
  font-size: 14px;

  h3 {
    font-weight: bold;
    margin: 4px 0px 12px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1px;

  .ant-btn {
    border-radius: 0px !important;
    padding-left: 12px;
    padding-right: 12px;

    &:first-child {
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }

    &:last-child {
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }
  }
`;

const StyledCard = styled(FlexColumn)<{ background?: string }>`
  margin-bottom: 16px;
  border: none;
  padding: 16px;
  background: ${props => (props.background ? props.background : COLORS.white)};
  display: flex;
  border-radius: 6px;
`;

const AlertCard = ({ title, secondaryTitle }) => (
  <StyledCard background={COLORS.coeblue11} gap={0}>
    <Typography fontWeight={600}>{title}</Typography>
    <Typography color="textSecondary" fontSize="12px">
      Since {secondaryTitle}
    </Typography>
  </StyledCard>
);

const StyledUnderLine = styled.div`
  text-decoration: underline;
  text-decoration-style: dotted;
`;

export const ManageTeamV2 = () => {
  const track = useTrack();

  const {
    basePath,
    getUserSubscription,
    enableSubscriptionUpdate,
    userSubscription,
    updateSubscription,
    billingMetadata,
    additionalSeats,
    plan_type,
    currentUserPlanManager,
    setAdditionalSeats,
    setContactSupportInfoBox,
    setPlanType,
    currentUserEmail,
    setBillingInterval,
    setDataSources,
    usersInCurrentPlan,

    otherUsersIndomain,

    planManagers,
    planMembers,
    isFreeUser,
    isManager,
    syncBilling,
    subscriptions,
  } = useContext(BillingContext) as BContext;

  useEffect(() => {
    track("billing_manage_team_viewed");
    document.title = "Manage Licenses - Coefficient Workspace";
  }, []);

  const history = useHistory();

  const subscriptionDetails = userSubscription?.subscription_details;
  const planType = subscriptionDetails?.plan_type || "";

  const adminEmails = planManagers.map(u => u.user?.email);

  const activeOtherUsersInDomain = getActiveOtherUsersInDomain(otherUsersIndomain).filter(
    u => adminEmails.indexOf(u.user?.email) === -1
  );

  const isGmailLikeDomain = getIsGmailLikeDomainFromEmail(currentUserEmail);

  const [showInviteUser, setShowInviteUser] = useState<boolean>(false);

  const [downgradeUserConfirm, setDowngradeUserConfirm] = useState<SubscriptionUser | null>(null);

  const [showEditSeatsPanel, setShowEditSeatsPanel] = useState<null | "add" | "remove">(null);

  const [showAddUserConfirm, setShowAddUserConfirm] = useState<boolean>(false);

  const [showAddUserToPlanModal, setShowAddUserToPlanModal] = useState<boolean>(false);

  const [initialUsersToAdd, setInitialUsersToAdd] = useState<Array<string>>([]);

  const [updateSeatsLoading, setUpdateSeatsLoading] = useState<boolean>(false);

  const [newlyInvitedUsers, setNewlyInvitedUsers] = useState<Array<string>>([]);

  const newlyInvitedUsersEmailsSet = new Set(newlyInvitedUsers);

  const maxUsers = subscriptionDetails?.max_users ?? 0;

  const invitedUsers = userSubscription?.invite_users?.length ?? 0;

  const freeSeats = maxUsers - usersInCurrentPlan.length - invitedUsers;

  const currentPlan = billingMetadata?.plans.find(
    plan =>
      plan.plan_type === planType && plan.billing_interval === subscriptionDetails?.billing_interval
  );

  const ownerHasSeatOnPlan = Boolean(
    usersInCurrentPlan.find(user => user.user.email === currentUserPlanManager?.email)
  );

  const isPlanOwnerWithoutLicenseOnPlan = !ownerHasSeatOnPlan;

  const avatarColorOffset = isPlanOwnerWithoutLicenseOnPlan ? 1 : 0;

  const downgradeUser = userId => {
    const selectedUsers = usersInCurrentPlan
      .filter(u => u.user?.user_id !== userId)
      .map(u => u.user?.user_id);

    updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "reassignment",
      selected_user_ids: selectedUsers,
      invite_user_emails: (userSubscription?.invite_users ?? []).map(u => u.user?.email),
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
      subscription_id: userSubscription?.subscription_details?.subscription_id,
    });

    track("billing_manage_team_user_removed", {
      removed_user: usersInCurrentPlan.find(u => userId === u.user?.user_id)?.user?.email,
    });
  };

  const removeInvitedUser = email => {
    updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "reassignment",
      invite_user_emails: (userSubscription?.invite_users ?? [])
        .filter(u => u.user?.email !== email)
        .map(u => u.user?.email),
      selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id),
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
      subscription_id: userSubscription?.subscription_details?.subscription_id,
    });

    track("billing_manage_team_user_removed", {
      removed_user: userSubscription?.invite_users?.find(u => email === u.user?.email)?.user?.email,
    });
  };

  const updateUserSeats = async () => {
    setUpdateSeatsLoading(true);
    const totalUserLicenses =
      showEditSeatsPanel === "add"
        ? (subscriptionDetails?.max_users ?? 0) + additionalSeats
        : showEditSeatsPanel === "remove"
        ? (subscriptionDetails?.max_users ?? 0) - additionalSeats
        : subscriptionDetails?.max_users ?? 0;

    await updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "modify_subscription",
      total_user_licenses: totalUserLicenses,
      invite_user_emails: (userSubscription?.invite_users ?? []).map(u => u.user?.email),
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id),
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
      subscription_id: userSubscription?.subscription_details?.subscription_id,
    });
    setShowEditSeatsPanel(null);
    setShowAddUserConfirm(false);
    setUpdateSeatsLoading(false);
    track(
      showEditSeatsPanel === "add"
        ? "billing_manage_team_licenses_added"
        : "billing_manage_team_licenses_removed",
      {
        selected_num_users: totalUserLicenses,
        [showEditSeatsPanel === "add" ? "num_licenses_added" : "num_licenses_removed"]:
          additionalSeats,
      }
    );
  };

  const updateSubscriptionOnServer = async body => {
    message.info("Updating subscription...");
    const response = await updateSubscription(body);
    if (!response.is_success) {
      message.error(response.error_msg || "Uh-oh. Something went wrong. Please try again");
    } else {
      await syncBilling();
      message.success("Your subscription has been updated");
    }
  };

  const addPlanMangerMutation = useMutation(
    async (user: SubscriptionUser) => {
      message.info("Updating subscription...");
      const updatedInfo = await updateSubscription({
        update_type: SubscriptionUpdateType.AddPlanManager,
        subscription_id: userSubscription?.subscription_details?.subscription_id,
        new_plan_manager_user_id: user.user_id!,
      });

      if (updatedInfo.is_success) {
        await syncBilling();
      }

      return updatedInfo;
    },
    {
      onSuccess: (result: UpdateSubscriptions200Response) => {
        if (!result.is_success) {
          message.error(result.error_msg || "Uh-oh. Something went wrong. Please try again");
        } else {
          message.success("Your subscription has been updated");
        }
      },
      onError: (error: Error) => {
        sentryCapture({ error, name: "AddPlanManager" });
        message.error("Failed to update subscription");
      },
    }
  );

  const removePlanMangerMutation = useMutation(
    async (user: SubscriptionUser) => {
      message.info("Updating subscription...");
      const updatedInfo = await updateSubscription({
        update_type: SubscriptionUpdateType.RemovePlanManager,
        subscription_id: userSubscription?.subscription_details?.subscription_id,
        new_plan_manager_user_id: user.user_id!,
      });

      if (updatedInfo.is_success) {
        await syncBilling();
      }

      return updatedInfo;
    },
    {
      onSuccess: (result: UpdateSubscriptions200Response) => {
        if (!result.is_success) {
          message.error(result.error_msg || "Uh-oh. Something went wrong. Please try again");
        } else {
          message.success("Your subscription has been updated");
        }
      },
      onError: (error: Error) => {
        sentryCapture({ error, name: "RemovePlanManager" });
        message.error("Failed to update subscription");
      },
    }
  );

  useEffect(() => {
    // set the selected billing details to populate the pricing summary
    if (subscriptionDetails?.plan_type) {
      setPlanType(subscriptionDetails?.plan_type);
    }
    if (subscriptionDetails?.billing_interval) {
      setBillingInterval(subscriptionDetails?.billing_interval);
    }
    if (subscriptionDetails?.selected_data_source_types) {
      setDataSources(subscriptionDetails?.selected_data_source_types);
    }
  }, [usersInCurrentPlan]);

  const openAddUserSeatsPopUp = () => {
    setAdditionalSeats(1);
    setShowEditSeatsPanel("add");
  };

  const openRemoveUserSeatsPopUp = () => {
    if (subscriptionDetails?.max_users) {
      setAdditionalSeats(1);
    }
    setShowEditSeatsPanel("remove");
  };

  const subscribedAdditionalUserSeats =
    (subscriptionDetails?.max_users ?? 0) -
    (userSubscription?.invite_users ?? []).length -
    usersInCurrentPlan.length;

  // for computing new pricing summary
  const planMonthlyPrice: number =
    billingMetadata?.plans.find(
      p => p.plan_type === subscriptionDetails?.plan_type && p.billing_interval === "monthly"
    )?.per_user_plan?.per_item_monthly_price_usd || 0;

  const planAnnualMonthlyPrice: number =
    billingMetadata?.plans.find(
      p => p.plan_type === subscriptionDetails?.plan_type && p.billing_interval === "annually"
    )?.per_user_plan?.per_item_monthly_price_usd || 0;

  const numberOfUserInPlan: number =
    showEditSeatsPanel === "add"
      ? (subscriptionDetails?.max_users ?? 0) + additionalSeats
      : showEditSeatsPanel === "remove"
      ? (subscriptionDetails?.max_users ?? 0) - additionalSeats
      : subscriptionDetails?.max_users ?? 0;

  const newPriceSummary = getPricingSummary(
    subscriptionDetails?.plan_type,
    subscriptionDetails?.billing_interval ?? "annually", // billing interval
    planMonthlyPrice, // monthly price for the plan (monthly interval)
    planAnnualMonthlyPrice, // monthly price for the plan (annual interval)
    currentPlan?.per_additional_data_source_addon?.per_item_monthly_price_usd || 0, // addtional data source cost
    numberOfUserInPlan, // # of users
    0, // # of data source should be 0 for add/remove seats to not display item
    billingMetadata?.coupon_discount_percentage
  );

  const manageLicensesMenu = (
    <Menu>
      <Menu.Item key={"add-licenses"}>
        <a href="javascript:void(0)" onClick={openAddUserSeatsPopUp}>
          Add Licenses
        </a>
      </Menu.Item>
      <Menu.Item key={"remove-licenses"}>
        <a href="javascript:void(0)" onClick={openRemoveUserSeatsPopUp}>
          Remove Licenses
        </a>
      </Menu.Item>
    </Menu>
  );

  const renderUserMenu = (user: SubscriptionUser) => {
    return (
      <Menu>
        <Menu.Item
          key="change-to-manager"
          onClick={async () => await addPlanMangerMutation.mutateAsync(user)}
        >
          Change to plan manager
        </Menu.Item>

        <Menu.Item key={"remove-user"} onClick={() => setDowngradeUserConfirm(user)}>
          Remove user from plan
        </Menu.Item>
      </Menu>
    );
  };

  const renderOtherUserInfo = (user: UserSubscriptionInfo) => {
    const managedByUser = user.managed_by_user;
    const onAnotherPlan = !!managedByUser;

    if (readOnly && !onAnotherPlan) {
      return null;
    }

    if (readOnly && onAnotherPlan) {
      return (
        <>
          <Typography color="textSecondary" fontWeight={500}>
            <Tooltip
              title={`Plan managed by ${managedByUser?.first_name || " "} ${
                managedByUser?.last_name || " "
              }`}
            >
              <StyledUnderLine>on another plan</StyledUnderLine>
            </Tooltip>
          </Typography>
          <Button type="text" size="small"></Button>
        </>
      );
    }

    if (onAnotherPlan) {
      return (
        <>
          <Typography color="textSecondary" fontWeight={500}>
            <Tooltip
              title={`Plan managed by ${managedByUser?.first_name || " "} ${
                managedByUser?.last_name || " "
              }`}
            >
              <StyledUnderLine>on another plan</StyledUnderLine>
            </Tooltip>
          </Typography>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="change-to-manager"
                  onClick={async () => await addPlanMangerMutation.mutateAsync(user.user)}
                >
                  Change to plan manager
                </Menu.Item>
              </Menu>
            }
            placement="bottomLeft"
          >
            <Button type="text" size="small">
              <EllipsisMenu />
            </Button>
          </Dropdown>
        </>
      );
    }

    return (
      <>
        <Button
          noPadding
          type="link"
          onClick={() => {
            const userId = user.user?.user_id;
            handleAddUserToPlan(userId, user.user.email);
          }}
        >
          Add user to plan
        </Button>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="change-to-manager"
                onClick={async () => await addPlanMangerMutation.mutateAsync(user.user)}
              >
                Change to plan manager
              </Menu.Item>

              <Menu.Item
                key={"add-user-to-plan"}
                onClick={() => handleAddUserToPlan(user.user?.user_id, user.user?.email)}
              >
                Add user to plan
              </Menu.Item>
            </Menu>
          }
          placement="bottomLeft"
        >
          <Button type="text" size="small">
            <EllipsisMenu />
          </Button>
        </Dropdown>
      </>
    );
  };

  const renderAdminInfo = (user: UserSubscriptionInfo) => {
    const managedByUser = user.managed_by_user;
    const onAnotherPlan = managedByUser?.email !== subscriptionDetails?.subscription_owner_email;

    if (!managedByUser) {
      return (
        <Button
          noPadding
          type="link"
          onClick={() => {
            const userId = user.user?.user_id;
            handleAddUserToPlan(userId, user.user.email);
          }}
        >
          Add user to plan
        </Button>
      );
    }

    if (onAnotherPlan) {
      return (
        <Typography color="textSecondary" fontWeight={500}>
          <Tooltip
            title={`Plan managed by ${managedByUser.first_name || " "} ${
              managedByUser.last_name || " "
            }`}
          >
            <StyledUnderLine> on another plan</StyledUnderLine>
          </Tooltip>
        </Typography>
      );
    }

    return (
      <Typography color="textSecondary" fontWeight={500}>
        on this plan
      </Typography>
    );
  };

  const renderAdminMenu = (info: UserSubscriptionInfo) => {
    const user = info.user;
    const managedByUser = info.managed_by_user;
    const isPlanOwner = info.user.email === subscriptionDetails?.subscription_owner_email;
    const onAnotherPlan =
      managedByUser && managedByUser?.email !== subscriptionDetails?.subscription_owner_email;

    if (isPlanOwner && !managedByUser) {
      return (
        <Menu>
          <Menu.Item key="change-to-member" disabled>
            <Tooltip title="Plan creator cannot be removed as plan manager">
              Remove as plan manager
            </Tooltip>
          </Menu.Item>
          <Menu.Item
            key={"add-user-to-plan"}
            onClick={() => handleAddUserToPlan(user?.user_id, user?.email)}
          >
            Add user to plan
          </Menu.Item>
        </Menu>
      );
    }

    if (isPlanOwner && onAnotherPlan) {
      return (
        <Menu>
          <Menu.Item key="change-to-member" disabled>
            <Tooltip title="Plan creator cannot be removed as plan manager">
              Remove as plan manager
            </Tooltip>
          </Menu.Item>
        </Menu>
      );
    }

    if (isPlanOwner && managedByUser) {
      return (
        <Menu>
          <Menu.Item key="change-to-member" disabled>
            <Tooltip title="Plan creator cannot be removed as plan manager">
              Remove as plan manager
            </Tooltip>
          </Menu.Item>
          <Menu.Item key={"remove-user"} onClick={() => setDowngradeUserConfirm(user)}>
            Remove user from plan
          </Menu.Item>
        </Menu>
      );
    }

    if (onAnotherPlan) {
      return (
        <Menu>
          <Menu.Item
            key="change-to-member"
            onClick={async () => await removePlanMangerMutation.mutateAsync(user)}
          >
            Remove as plan manager
          </Menu.Item>
        </Menu>
      );
    }

    if (!managedByUser) {
      return (
        <Menu>
          <Menu.Item
            key="change-to-member"
            onClick={async () => await removePlanMangerMutation.mutateAsync(user)}
          >
            Remove as plan manager
          </Menu.Item>
          <Menu.Item
            key={"add-user-to-plan"}
            onClick={() => handleAddUserToPlan(user?.user_id, user?.email)}
          >
            Add user to plan
          </Menu.Item>
        </Menu>
      );
    }

    return (
      <Menu>
        <Menu.Item
          key="change-to-member"
          onClick={async () => await removePlanMangerMutation.mutateAsync(user)}
        >
          Remove as plan manager
        </Menu.Item>
        <Menu.Item key={"remove-user"} onClick={() => setDowngradeUserConfirm(user)}>
          Remove user from plan
        </Menu.Item>
      </Menu>
    );
  };

  const handleAddUsers = () => {
    setShowAddUserToPlanModal(true);
  };

  const handleAddUserToPlan = (userId: string | undefined, email: string | undefined) => {
    if (!userId) {
      return;
    }

    if (freeSeats > 0) {
      upgradeUser(userId);
    } else {
      if (!email) {
        return;
      }
      setInitialUsersToAdd([email]);
      setShowAddUserToPlanModal(true);
    }
  };

  const upgradeUser = async (userId: string) => {
    const selectedUsers = usersInCurrentPlan.map(u => u.user?.user_id);

    selectedUsers.push(userId);

    await updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "reassignment",
      invite_user_emails: (userSubscription?.invite_users ?? []).map(u => u.user?.email),
      selected_user_ids: selectedUsers,
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
      subscription_id: userSubscription?.subscription_details?.subscription_id,
    });

    track("billing_manage_team_user_added", {
      event_from: "user_list",
      added_users: otherUsersIndomain
        .filter(u => userId === u.user?.user_id)
        .map(u => u.user?.email),
    });
  };

  const readOnly = isGmailLikeDomain || !isManager;

  return (
    <Container>
      <Breadcrumb>
        {!!subscriptions?.other_subscriptions?.length && (
          <BreadcrumbItem>
            <Link to={{ pathname: `${basePath}/subscriptions`, search: window.location.search }}>
              Subscriptions
            </Link>
          </BreadcrumbItem>
        )}
        <BreadcrumbItem>
          <Link to={{ pathname: `${basePath}/subscription`, search: window.location.search }}>
            Plans & Billing
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Manage Licenses</BreadcrumbItem>
      </Breadcrumb>

      <Content>
        <TeamInfoContainer>
          <FlexRow style={{ justifyContent: "space-between", marginBottom: "24px" }}>
            <Typography fontSize="26px" fontWeight={700}>
              Manage Licenses
            </Typography>
            <FlexRow gap={0}>
              {!readOnly && (
                <ButtonGroup>
                  <Button type="primary" onClick={handleAddUsers}>
                    Add Users
                  </Button>
                  {planType === "pro" && (
                    <Dropdown
                      overlay={enableSubscriptionUpdate ? manageLicensesMenu : <></>}
                      placement="bottomLeft"
                    >
                      <Button
                        type="primary"
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          if (!enableSubscriptionUpdate) {
                            setContactSupportInfoBox({ title: "Manage Licenses" });
                          }
                        }}
                      >
                        <EllipsisMenu height={"12px"} fill="currentColor" />
                      </Button>
                    </Dropdown>
                  )}
                </ButtonGroup>
              )}
            </FlexRow>
          </FlexRow>

          {isFreeUser && (
            <AlertCard
              title={`You’re on the ${capitalize(planType)} plan`}
              secondaryTitle={moment(subscriptionDetails?.subscription_active_since_dt).format(
                "MMM DD, YYYY"
              )}
            />
          )}

          <FlexColumn>
            <Typography fontWeight={"bold"} fontSize="18px" style={{ marginBottom: "8px" }}>
              Plan managers
            </Typography>

            {planManagers.map((user, i) => (
              <UserList
                key={i}
                avatarColor={avatarColors[avatarColorOffset + (i % avatarColors.length)]}
                name={`${user.user?.first_name || user.user.email} ${user.user?.last_name || ""}`}
                email={user.user?.email || ""}
                userId={user.user?.user_id || ""}
                plan={user.plan_type ?? SubscriptionPlanType.FreeTrial}
                footer={
                  !readOnly && (
                    <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
                      <FlexRow gap={2} style={{ justifyContent: "space-between" }}>
                        {renderAdminInfo(user)}
                        <Dropdown overlay={renderAdminMenu(user)} placement="bottomLeft">
                          <Button type="text" size="small">
                            <EllipsisMenu />
                          </Button>
                        </Dropdown>
                      </FlexRow>
                    </FlexRow>
                  )
                }
              />
            ))}
          </FlexColumn>

          <FlexColumn style={{ marginTop: "24px" }}>
            <Typography fontWeight={"bold"} fontSize="18px" style={{ marginBottom: "8px" }}>
              Plan members
            </Typography>

            {planMembers.length === 0 && subscribedAdditionalUserSeats === 0 && (
              <UserList
                className="assignUsers"
                avatarColor={COLORS.black25}
                avatarIcon={<UserOutlined />}
                name={`No member on this plan`}
                userId={""}
                isSelectable={false}
                footer={
                  !readOnly && (
                    <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
                      <Button noPadding type="link" onClick={handleAddUsers}>
                        Add user to plan
                      </Button>
                      <Button></Button>
                    </FlexRow>
                  )
                }
              />
            )}

            {planMembers.map((user, i) => (
              <UserList
                key={i}
                avatarColor={avatarColors[avatarColorOffset + (i % avatarColors.length)]}
                name={`${user.user?.first_name || user.user.email} ${user.user?.last_name || ""}`}
                email={user.user?.email || ""}
                userId={user.user?.user_id || ""}
                plan={user.plan_type ?? SubscriptionPlanType.FreeTrial}
                footer={
                  <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
                    <FlexRow gap={2} style={{ justifyContent: "space-between" }}>
                      <Typography color="textSecondary" fontWeight={500}>
                        on this plan
                      </Typography>
                      {readOnly ? (
                        <Button type="text" size="small" />
                      ) : (
                        <Dropdown overlay={renderUserMenu(user.user)} placement="bottomLeft">
                          <Button type="text" size="small">
                            <EllipsisMenu />
                          </Button>
                        </Dropdown>
                      )}
                    </FlexRow>
                  </FlexRow>
                }
              />
            ))}

            {subscribedAdditionalUserSeats > 0 && !readOnly && (
              <UserList
                className="assignUsers"
                avatarColor={COLORS.black25}
                avatarIcon={<UserOutlined />}
                name={`${subscribedAdditionalUserSeats} Unassigned License${pluralize(
                  subscribedAdditionalUserSeats
                )}`}
                userId={""}
                isSelectable={false}
                footer={
                  !readOnly && (
                    <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
                      <Button noPadding type="link" onClick={handleAddUsers}>
                        Assign license
                      </Button>
                      <Button></Button>
                    </FlexRow>
                  )
                }
              />
            )}
          </FlexColumn>

          {activeOtherUsersInDomain.length > 0 && (
            <FlexColumn style={{ marginTop: "24px" }}>
              <Typography fontWeight={700} variant="h6">
                Other users from your domain
              </Typography>
              {activeOtherUsersInDomain.map((user, i) => (
                <UserList
                  key={i}
                  avatarColor={
                    avatarColors[
                      (planManagers.length + i + avatarColorOffset) % avatarColors.length
                    ]
                  }
                  name={`${user.user?.first_name || user.user.email} ${user.user?.last_name || ""}`}
                  email={user.user?.email || ""}
                  userId={user.user?.user_id || ""}
                  plan={user.plan_type ?? SubscriptionPlanType.FreeTrial}
                  footer={
                    <FlexRow style={{ justifyContent: "flex-end" }}>
                      {renderOtherUserInfo(user)}
                    </FlexRow>
                  }
                />
              ))}
            </FlexColumn>
          )}
        </TeamInfoContainer>

        <HelpContent />
      </Content>

      {/* Confirm Downgrade to free popup */}
      <ConfirmBox
        closable={true}
        title="Downgrade to Free!"
        visible={downgradeUserConfirm !== null}
        okText={`Yes, Downgrade to Free`}
        cancelText="Cancel"
        onOk={() => {
          if (downgradeUserConfirm?.user_id) {
            downgradeUser(downgradeUserConfirm?.user_id);
          } else if (downgradeUserConfirm?.email) {
            removeInvitedUser(downgradeUserConfirm?.email);
          }
          setDowngradeUserConfirm(null);
        }}
        onCancel={() => setDowngradeUserConfirm(null)}
        width={440}
      >
        <>
          <p>
            <b>
              {downgradeUserConfirm?.first_name ?? downgradeUserConfirm?.email}{" "}
              {downgradeUserConfirm?.last_name}
            </b>{" "}
            will be removed from your {planNameFromType(planType)} plan and downgraded to the Free
            plan.
          </p>
          <p
            style={{
              borderRadius: "5px",
              color: COLORS.black65,
              background: COLORS.yellowBg2,
              padding: "8px 8px 8px 8px",
            }}
          >
            Scheduled imports and exporting to data sources will no longer work and other users
            won't be able to refresh{" "}
            {downgradeUserConfirm?.first_name ?? downgradeUserConfirm?.email}{" "}
            {downgradeUserConfirm?.last_name}
            's imports
          </p>
        </>
      </ConfirmBox>

      {/* Add/Remove user seats popup */}
      <ConfirmBox
        title={`${showEditSeatsPanel === "add" ? "Add additional" : "Remove"} user licenses`}
        visible={showEditSeatsPanel !== null && !showAddUserConfirm}
        okText={
          showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats === 0 ? "Got it" : "Next"
        }
        onOk={() => {
          if (showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats === 0) {
            setShowEditSeatsPanel(null);
            return;
          }
          if (additionalSeats > 0) {
            setShowAddUserConfirm(true);
          }
        }}
        width={560}
        onCancel={() => setShowEditSeatsPanel(null)}
        maskClosable={true}
      >
        <>
          {showEditSeatsPanel === "add" && (
            <p>You can assign licenses to users once you purchase.</p>
          )}
          {showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats > 0 && (
            <p>
              You have {subscribedAdditionalUserSeats} unassigned license
              {pluralize(subscribedAdditionalUserSeats)} on your plan that you can remove.
            </p>
          )}
          {showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats === 0 && (
            <p>
              You can only remove unassigned licenses from your plan. All licenses are currently
              assigned. Please unassign a license first.
            </p>
          )}
          {((showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats > 0) ||
            showEditSeatsPanel === "add") && (
            <AddSeats
              onChange={val => {
                if (val < 1) {
                  setAdditionalSeats(1);
                  return;
                }
                if (showEditSeatsPanel === "remove") {
                  setAdditionalSeats(
                    val < subscribedAdditionalUserSeats ? val : subscribedAdditionalUserSeats
                  );
                } else {
                  setAdditionalSeats(val);
                }
              }}
              onAddUser={() =>
                showEditSeatsPanel === "remove"
                  ? setAdditionalSeats(
                      additionalSeats < subscribedAdditionalUserSeats
                        ? additionalSeats + 1
                        : additionalSeats
                    )
                  : setAdditionalSeats(additionalSeats + 1)
              }
              onRemoveUser={() => {
                if (additionalSeats > 1) {
                  setAdditionalSeats(additionalSeats - 1);
                }
              }}
              userCount={additionalSeats}
            />
          )}
          <div style={{ height: "50px" }}></div>
        </>
      </ConfirmBox>

      {/* Confirm Add/Remove user seats popup */}
      <ConfirmBox
        title={`Confirm plan changes`}
        visible={showAddUserConfirm}
        okText={showEditSeatsPanel === "add" ? `Confirm & Pay Now` : `Confirm changes`}
        cancelText={`Cancel`}
        onOk={() => updateUserSeats()}
        onCancel={() => {
          setShowAddUserConfirm(false);
          setShowEditSeatsPanel(null);
        }}
        width={560}
        confirmLoading={updateSeatsLoading}
      >
        <>
          <p>
            {additionalSeats} Pro user license{pluralize(additionalSeats)} will be{" "}
            {showEditSeatsPanel === "add" ? "added" : "removed"} to your plan
          </p>
          <PricingSummaryWrap>
            <PriceSummaryProratedV2
              editType={showEditSeatsPanel === "add" ? "add-seats" : "remove-seats"}
              newPriceSummary={newPriceSummary}
            />
          </PricingSummaryWrap>
        </>
      </ConfirmBox>

      {/* Invite users popup */}
      <InviteUserToPlanModal open={showInviteUser} onClose={() => setShowInviteUser(false)} />

      {/* Add Users popup */}
      {showAddUserToPlanModal && (
        <AddUserToPlanModal
          onInvitedCb={invitedUsers => {
            setNewlyInvitedUsers(invitedUsers);
          }}
          open={showAddUserToPlanModal}
          initialUsersToAdd={initialUsersToAdd}
          onClose={() => {
            setShowAddUserToPlanModal(false);
            setInitialUsersToAdd([]);
          }}
        />
      )}
    </Container>
  );
};
