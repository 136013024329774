import { SubscriptionDetails, UserRoleType, UserSubscriptionInfo } from "@coeff/api";

export const isPlanManager = (
  user: UserSubscriptionInfo,
  subscriptionDetails?: SubscriptionDetails
) => user?.is_plan_manager || user.user.email === subscriptionDetails?.subscription_owner_email;

export const isPlanMember = (
  user: UserSubscriptionInfo,
  subscriptionDetails?: SubscriptionDetails
) => user.managed_by_user?.email === subscriptionDetails?.subscription_owner_email;

export const isFreeUser = (user: UserSubscriptionInfo, loggedInUserEmail: string) =>
  !user.managed_by_user && user.user.email === loggedInUserEmail;

export const sortUsers = (a: UserSubscriptionInfo, b: UserSubscriptionInfo) => {
  const bName = `${b.user.first_name} ${b.user.last_name}`;
  const aName = `${a.user.first_name} ${a.user.last_name}`;

  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};
