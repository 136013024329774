import {
  DataSourceType,
  DueInvoiceErrorType,
  FeatureFlag,
  GetSubscriptionInfo200Response,
  GetSubscriptions200Response,
  SubscriptionBillingInterval,
  SubscriptionInvoice,
  SubscriptionPlanType,
  SubscriptionUser,
  UserRoleType,
  UserSubscriptionInfo,
} from "@coeff/api";
import * as Sentry from "@sentry/browser";
import { message } from "antd";
import querystring from "query-string";
import React, { ReactNode, useEffect, useState } from "react";
import { MapDispatchToProps, MapStateToProps, connect } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { AppState } from "src/store";
import { useGate } from "statsig-react";
import styled from "styled-components";

import {
  AppThunkDispatch,
  checkout,
  getInvoices,
  getPreCheckoutData,
  getUserSubscription,
  getAllSubscriptions,
  updateSubscription,
} from "../../actions";
import {
  Alert,
  CoeLogo,
  ConfirmBox,
  EventAttributionIframe,
  LoaderWithPerfTimings,
  Typography,
} from "../../components";
import { BContext, BillingPage, BillingPreCheckoutData } from "../../types";
import { getPricingSummary, useTrack } from "../../utils";

import { isPlanManager, isPlanMember, sortUsers } from "./billing.utils";
import { BillingHistory } from "./pages/BillingHistory";
import { Payment } from "./pages/Payment";
import { UpdatePayment } from "./pages/UpdatePayment";
import { ManageTeamV2 } from "./pagesV2/ManageTeam";
import { PlanCustomizerV2 } from "./pagesV2/PlanCustomizer";
import { PlanSelectorV2 } from "./pagesV2/PlanSelector";
import { SubscriptionInfoV2 } from "./pagesV2/SubscriptionInfo";
import { TeamV2 } from "./pagesV2/Team";
import { Subscriptions } from "./Subscriptions";

const Wrap = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 1rem;

  &.iframe {
    padding: 0px;
  }

  &.excel {
    padding: 24px 16px 16px 16px;
  }

  &.dashboard {
    margin: 0px;
    padding: 20px 30px;
  }
`;

const CoeLogoSmall = styled(CoeLogo)`
  width: 120px;
  margin: 10px 0 18px 0;
`;

const isExcel = querystring.parse(window.location.search).platform === "excel_addon";

const isIFrame = querystring.parse(window.location.search).isiframe === "true";

const userEmail = querystring.parse(window.location.search).email;

export const BillingContext = React.createContext<BContext | null>(null);

type ConnectDispatchProps = {
  checkout: (body: any) => Promise<unknown>;
  getPreCheckoutData: (subscriptionId?: string) => Promise<unknown>;
  getUserSubscription: (subscriptionId?: string) => Promise<GetSubscriptionInfo200Response>;
  getAllSubscriptions: () => Promise<GetSubscriptions200Response>;
  getInvoices: (subscriptionId?: string) => void;
  updateSubscription: (body: any) => Promise<unknown>;
};

type ConnectProps = {
  coeffUserEmail: string;
  metadata: BillingPreCheckoutData;
  userSubscription?: GetSubscriptionInfo200Response | null;
  free_trial_expiration_dt?: Date;
  free_trial_total_days?: number;
  plan_type?: SubscriptionPlanType;
  due_invoices_count?: number;
  due_invoice_error_type?: DueInvoiceErrorType;
  invoices: Array<SubscriptionInvoice>;
  upgradeToken?: string;
  domains_in_domain_family: string[];

  subscriptions: GetSubscriptions200Response | null;
};

type Props = ConnectProps & ConnectDispatchProps;

const Billing: React.FC<Props> = ({
  checkout,
  coeffUserEmail,
  getPreCheckoutData,
  getUserSubscription,
  getInvoices,
  metadata: billingMetadata,
  userSubscription,
  updateSubscription,
  free_trial_expiration_dt,
  free_trial_total_days,
  plan_type,
  due_invoices_count,
  due_invoice_error_type,
  invoices,
  upgradeToken,
  domains_in_domain_family,
  getAllSubscriptions,
  subscriptions,
}) => {
  const track = useTrack();

  const { value: enableGmailDiscountTake50Coeff } = useGate(
    FeatureFlag.EnableGmailDiscountTake50coeff
  );

  const [page, setPage] = useState<BillingPage>(
    userSubscription ? "subscription-info" : "plan-selector"
  );

  const [loading, setLoading] = useState<boolean>(true);

  const [selectedPlanType, setPlanType] = useState<SubscriptionPlanType | undefined>();

  const [selectedBillingInterval, setBillingInterval] =
    useState<SubscriptionBillingInterval>("annually");

  const [selectedDataSources, setDataSources] = useState<DataSourceType[]>([]);

  const [selectedUsers, setUsers] = useState<SubscriptionUser[]>([]);

  const [invitedUserEmails, setInvitedUserEmails] = useState<string[]>([]);

  const [checkoutSuccess, setCheckoutSuccess] = useState<boolean>(false);

  const [additionalSeats, setAdditionalSeats] = useState<number>(0);

  const [contactSupportInfoBox, setContactSupportInfoBox] = useState<{
    title: string;
    message?: ReactNode;
  }>();

  const currentUserEmail = coeffUserEmail ?? userEmail;
  const domain = currentUserEmail ? currentUserEmail.split("@").pop() : undefined;

  const enableSubscriptionUpdate: boolean =
    userSubscription?.subscription_details?.checkout_mode !== "contact_us";

  const history = useHistory();

  const location = useLocation();

  const { path } = useRouteMatch();

  const searchParams = new URLSearchParams(location.search);

  const upgradeToPro = searchParams.get("upgrade_to_pro");

  const subscriptionId = searchParams.get("subscription_id") as string | undefined;

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await getPreCheckoutData(subscriptionId);

      const [response] = await Promise.all([
        getUserSubscription(subscriptionId),
        getAllSubscriptions(),
      ]);

      if (!response.subscription_details) {
        history.push({
          pathname: `${path}/subscriptions`,
          search: "",
        });

        if (subscriptionId) {
          message.error("Not allowed to view this page");
        }
      }

      setLoading(false);

      setBillingInterval(response.subscription_details?.billing_interval ?? "annually");

      const eventFrom = searchParams.get("from");
      if (!upgradeToPro) {
        track("billing_plan_chooser_viewed", { event_from: eventFrom ?? undefined });
      }

      window.opener?.postMessage(JSON.stringify({ type: "billing:loaded" }), "*");
    };

    init();

    document.title = "Plans & Billing - Coefficient Workspace";
  }, [subscriptionId]);

  const syncBilling = async () => {
    const [_, response] = await Promise.all([
      getPreCheckoutData(subscriptionId),
      getUserSubscription(subscriptionId),
    ]);

    if (!response.subscription_details) {
      history.push({
        pathname: `${path}/subscriptions`,
        search: "",
      });
    }
  };

  useEffect(() => {
    if (!loading && !billingMetadata.plans.length) {
      Sentry.captureException(new Error("Session expired displayed"));
    }
  }, [loading, billingMetadata]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (upgradeToPro) {
      setPlanType("pro");
      track("billing_plan_selected", {
        event_from: "email_import_refresh_blocked",
        selected_frequency: selectedBillingInterval,
        selected_plan_type: "pro",
      });
      history.push({
        pathname: `${path}/customize`,
        search: window.location.search,
      });
    } else if (userSubscription && location.pathname === `${path}/plans`) {
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams.get("from") === "billing") {
        return;
      }
      history.push({
        pathname: `${path}/subscription`,
        search: window.location.search,
      });
    } else if (!userSubscription && !subscriptions?.other_subscriptions?.length) {
      history.push({
        pathname: `${path}/plans`,
        search: window.location.search,
      });
    }
  }, [loading, userSubscription]);

  useEffect(() => {
    if (Boolean(checkoutSuccess)) {
      // we delay 10s before updating the props and analytics to give the iframe time to load GTM and other tracking scripts
      setTimeout(() => {
        setCheckoutSuccess(false);
      }, 10_000);
    }
  }, [checkoutSuccess]);

  const planData = billingMetadata?.plans.find(
    p => p.plan_type === selectedPlanType && p.billing_interval === selectedBillingInterval
  );

  const planMonthlyPrice: number =
    billingMetadata?.plans.find(
      p => p.plan_type === selectedPlanType && p.billing_interval === "monthly"
    )?.per_user_plan?.per_item_monthly_price_usd || 0;

  const planAnnualMonthlyPrice: number =
    billingMetadata?.plans.find(
      p => p.plan_type === selectedPlanType && p.billing_interval === "annually"
    )?.per_user_plan?.per_item_monthly_price_usd || 0;

  const priceSummary = getPricingSummary(
    planData?.plan_type, // plan type
    selectedBillingInterval, // billing interval
    planMonthlyPrice, // monthly price for the plan (monthly interval)
    planAnnualMonthlyPrice, // monthly price for the plan (annual interval)
    planData?.per_additional_data_source_addon?.per_item_monthly_price_usd || 0, // addtional data source cost
    selectedUsers.length + additionalSeats + invitedUserEmails.length, // # of users
    selectedDataSources.length, // # of data souce
    // for visual purposes only, we add a 50 percent discount for gmail users when viewing the price summary
    // this DOES NOT affect the actual price calculation during checkout
    enableGmailDiscountTake50Coeff && domain === "gmail.com"
      ? 50
      : billingMetadata?.coupon_discount_percentage
  );

  // Subscription information
  const usersInCurrentPlan: UserSubscriptionInfo[] = [];
  const otherUsersIndomain: UserSubscriptionInfo[] = [];

  const currentUserPlanManager = userSubscription?.users_from_domain.find(
    u => u.user.email === userSubscription?.subscription_details?.subscription_owner_email
  )?.user;

  userSubscription?.users_from_domain.forEach(user => {
    if (user.managed_by_user?.email === currentUserPlanManager?.email) {
      usersInCurrentPlan.push(user);
    } else {
      otherUsersIndomain.push(user);
    }
  });

  otherUsersIndomain.sort((a, b) => {
    return (b.num_imports || 0) - (a.num_imports || 0);
  });

  const basePath = path;

  const planManagers = (userSubscription?.users_from_domain || [])
    .filter(user => isPlanManager(user, userSubscription?.subscription_details))
    .sort(sortUsers);

  const planMembers = (userSubscription?.users_from_domain || [])
    .filter(
      user =>
        isPlanMember(user, userSubscription?.subscription_details) &&
        !isPlanManager(user, userSubscription?.subscription_details)
    )
    .concat(userSubscription?.invite_users || [])
    .sort(sortUsers);

  const isManager = [
    subscriptions?.user_subscription?.is_plan_manager
      ? subscriptions?.user_subscription?.user_subscription?.subscription_id
      : undefined,
    ...(subscriptions?.other_subscriptions?.map(subs => subs.subscription_id) || []),
  ].find(
    subId =>
      subId ===
      (subscriptionId || subscriptions?.user_subscription?.user_subscription?.subscription_id)
  );

  const isFreeUser = !userSubscription?.users_from_domain.find(
    user => user.user.email === currentUserEmail
  )?.managed_by_user;

  return (
    <BillingContext.Provider
      value={{
        additionalSeats,
        basePath,
        billingMetadata,
        checkout,
        checkoutSuccess,
        currentUserEmail,
        enableSubscriptionUpdate,
        domain,
        free_trial_expiration_dt,
        free_trial_total_days,
        getUserSubscription,
        getInvoices,
        page,
        plan_type,
        priceSummary,
        selectedPlanType,
        selectedBillingInterval,
        selectedDataSources,
        selectedUsers,
        setCheckoutSuccess,
        setContactSupportInfoBox,
        setPage,
        setPlanType,
        setBillingInterval,
        setDataSources,
        setAdditionalSeats,
        setUsers,
        // @ts-ignore - doesn't account for error object coming from reducer action
        updateSubscription,
        userSubscription,
        due_invoices_count,
        due_invoice_error_type,
        usersInCurrentPlan,
        otherUsersIndomain,
        currentUserPlanManager,
        invoices,
        upgradeToken,
        domains_in_domain_family,
        invitedUserEmails,
        setInvitedUserEmails,

        syncBilling,
        subscriptions,
        planManagers,
        planMembers,
        isFreeUser,
        isManager: !!isManager,
      }}
    >
      {loading ? (
        <div style={{ margin: "7rem auto 2rem", textAlign: "center" }}>
          <LoaderWithPerfTimings name="Billing" size="large" />
        </div>
      ) : billingMetadata.plans.length > 0 ? (
        <>
          <Wrap
            className={`${isIFrame ? "iframe" : ""} ${isExcel ? "excel" : ""} ${
              location.pathname.includes("dashboard") ? "dashboard" : ""
            }`}
          >
            {Boolean(checkoutSuccess) && (
              <EventAttributionIframe
                email={currentUserEmail}
                event_name="Purchase"
                event_props={{
                  plan_type: userSubscription?.subscription_details?.plan_type,
                  currency: "USD",
                  amount: userSubscription?.subscription_details?.recurring_amount_usd,
                  billing_interval: userSubscription?.subscription_details?.billing_interval,
                }}
              />
            )}
            {!isIFrame &&
              !location.pathname.includes("dashboard") &&
              location.pathname !== `${path}/update-payment` && <CoeLogoSmall />}
            <Switch>
              <Route exact path={`${path}`}>
                <Redirect
                  to={{
                    pathname: `${path}/plans`,
                    search: window.location.search,
                  }}
                />
              </Route>
              <Route path={`${path}/plans`}>
                <PlanSelectorV2 />
              </Route>
              <Route path={`${path}/customize`}>
                <PlanCustomizerV2 />
              </Route>
              <Route path={`${path}/payment`}>
                <Payment />
              </Route>
              <Route path={`${path}/subscriptions`} exact>
                <Subscriptions />
              </Route>
              <Route path={`${path}/subscription`}>
                <SubscriptionInfoV2 />
              </Route>
              <Route path={`${path}/billing`}>
                <BillingHistory />
              </Route>
              <Route path={`${path}/team/manage`}>
                <ManageTeamV2 />
              </Route>
              <Route path={`${path}/team`}>
                <TeamV2 />
              </Route>
              <Route path={`${path}/update-payment`}>
                <UpdatePayment />
              </Route>
            </Switch>
          </Wrap>

          <ConfirmBox
            title={contactSupportInfoBox?.title}
            visible={Boolean(contactSupportInfoBox)}
            onOk={() => setContactSupportInfoBox(undefined)}
            okText="Got it"
            width={400}
            okButtonProps={{
              style: { marginLeft: "auto" },
            }}
          >
            {contactSupportInfoBox?.message ? (
              contactSupportInfoBox.message
            ) : (
              <>
                Please contact us at{" "}
                <a href="mailto:support@coefficient.io" target="_blank">
                  support@coefficient.io
                </a>
                <br />
                to make changes
              </>
            )}
          </ConfirmBox>
        </>
      ) : (
        <div>
          <Alert
            message={
              <Typography variant="body1" fontWeight="bold">
                Session expired
              </Typography>
            }
            description={
              <a href="/dashboard/billing" target="_blank" style={{ fontWeight: "bold" }}>
                Back to Plans & Billing
              </a>
            }
            type="error"
            style={{ margin: "1rem", padding: 16 }}
          />
        </div>
      )}
    </BillingContext.Provider>
  );
};

const mapDispatchToProps: MapDispatchToProps<ConnectDispatchProps, unknown> = (
  dispatch: AppThunkDispatch
) => ({
  getPreCheckoutData: (subscriptionId?: string) => dispatch(getPreCheckoutData(subscriptionId)),
  getUserSubscription: (subscriptionId?: string) => dispatch(getUserSubscription(subscriptionId)),
  getAllSubscriptions: () => dispatch(getAllSubscriptions()),
  getInvoices: (subscriptionId?: string) => dispatch(getInvoices(subscriptionId)),
  updateSubscription: body => dispatch(updateSubscription(body)),
  checkout: body => dispatch(checkout(body)),
});

const mapStateToProps: MapStateToProps<ConnectProps, unknown, AppState> = ({
  billing: { metadata, userSubscription, invoices, subscriptions },
  app: {
    coeffUserEmail,
    free_trial_expiration_dt,
    free_trial_total_days,
    plan_type,
    due_invoices_count,
    due_invoice_error_type,
    upgradeToken,
    domains_in_domain_family,
  },
}) => ({
  metadata,
  userSubscription,
  coeffUserEmail,
  free_trial_expiration_dt,
  free_trial_total_days,
  plan_type,
  due_invoices_count,
  due_invoice_error_type,
  invoices,
  upgradeToken,
  domains_in_domain_family,
  subscriptions,
});

const route = withRouter(connect(mapStateToProps, mapDispatchToProps)(Billing));

export { route as Billing };
