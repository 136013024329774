import {
  GetSubscriptionInfo200Response,
  GetSubscriptions200Response,
  SubscriptionInvoice,
} from "@coeff/api";
import { Reducer } from "redux";

import { ACTIONS } from "../constants";
import { BillingPreCheckoutData } from "../types";

type BillingProps = {
  loading: boolean;
  metadata: BillingPreCheckoutData;
  userSubscription?: GetSubscriptionInfo200Response | null;
  invoices: SubscriptionInvoice[];
  subscriptions: GetSubscriptions200Response | null;
};

const initialState: BillingProps = {
  loading: false,
  metadata: { all_data_source_types: [], plans: [], users_from_domain: [] },
  userSubscription: undefined,
  invoices: [],
  subscriptions: null,
};

export const billing: Reducer<BillingProps> = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_BILLING_LOADING:
      return { ...state, loading: true };

    case ACTIONS.SET_BILLING_METADATA: {
      const { metadata } = action;
      return { ...state, loading: false, metadata };
    }

    case ACTIONS.SET_BILLING_SUBSCRIPTION: {
      const { userSubscription } = action;
      return { ...state, userSubscription };
    }

    case ACTIONS.SET_BILLING_INVOICES: {
      const { invoices } = action;
      return { ...state, invoices };
    }

    case ACTIONS.SET_ALL_SUBSCRIPTIONS: {
      const { subscriptions } = action;
      return { ...state, subscriptions };
    }

    default:
      return state;
  }
};
