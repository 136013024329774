import { User } from "@coeff/api";
import { Dropdown, Menu } from "antd";
import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants";
import { EllipsisMenu } from "../Icons";
import { Tooltip } from "../Tooltip";

const ActionsWrap = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding-right: 0;
  background: transparent;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    background: none;
    border: none;
    height: 32px;
    width: 32px;
    opacity: 0.5;
    padding: 8px;
    margin: 0px 4px 0px;
    border-radius: 50%;
    cursor: pointer;

    svg {
      width: 100%;
    }
    opacity: 1;
    &:hover {
      background: ${COLORS.black9};
    }

    &:disabled {
      opacity: 0.4;
    }
  }
`;

const UserRoleOptionsMenu = styled(Menu)`
  padding-bottom: 0px;
  width: 200px;
  z-index: 1000;

  .ant-dropdown-menu-item {
    color: ${COLORS.black85};

    &:hover {
      background: ${COLORS.coeblue};
    }

    &.ant-dropdown-menu-item-disabled {
      color: ${COLORS.black25};

      &:hover {
        background: none;
      }
    }
  }
`;

type Props = {
  noBackground?: boolean;
  noPadding?: boolean;
  user: User;
  onUpdateRole: () => void;
};

export const UserActionMenu = ({ user, onUpdateRole, noBackground, noPadding }: Props) => {
  const renderUserActionMenu = () => (
    <UserRoleOptionsMenu>
      <UserRoleOptionsMenu.Item key="rename" onClick={onUpdateRole}>
        <Tooltip title={"Update Role of the User"} overlayStyle={{ maxWidth: "200px" }}>
          <div>Manage Role</div>
        </Tooltip>
      </UserRoleOptionsMenu.Item>
    </UserRoleOptionsMenu>
  );

  return (
    <>
      <ActionsWrap className="actionswrap ellipsis">
        <div onClick={e => e.stopPropagation()}>
          <Dropdown
            overlay={renderUserActionMenu}
            trigger={["click"]}
            getPopupContainer={item => item}
          >
            <button>
              <EllipsisMenu fill={COLORS.black} />
            </button>
          </Dropdown>
        </div>
      </ActionsWrap>
    </>
  );
};
