import { Radio as RadioAD } from "antd";
import styled from "styled-components";

import { COLORS } from "../constants";

const StyledRadio = styled(RadioAD)`
  .ant-radio-inner {
    border-width: 2px;
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: ${COLORS.coeblue4};

      &:after {
        top: 6px;
        left: 6px;
        background-color: ${COLORS.coeblue4};
      }
    }

    &:after {
      animation-duration: 0s !important;
    }
  }

  .ant-radio-button-wrapper-checked {
    background: ${COLORS.coeblue4};
    border-color: ${COLORS.coeblue4};
  }

  &.error {
    .ant-radio {
      .ant-radio-inner {
        border-color: ${COLORS.red};
      }
    }
  }
`;

export const Radio = StyledRadio;
