import { ArrowDownOutlined, ArrowUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { User, UserRoleType } from "@coeff/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { SortColumn, SortDirection } from "../../../actions";
import { fetchAdminDomainUsers, useAdminDomainUsers } from "../../../api/users";
import {
  AdminTableFooter,
  UserActionMenu,
  LoaderWithPerfTimings,
  Table,
  TableDropdown,
  TableFilterTags,
  Typography,
  ConfirmBox,
  Button,
  Radio,
  Avatar,
  Alert,
  FlexColumn,
} from "../../../components";
import { AVATAR_COLORS, COLORS, KEY_LABEL } from "../../../constants";
import { AppState } from "../../../store";
import { EAContext } from "../../../types";
import { AxiosErrorWithMessage, useApiContext } from "../../../utils";
import { ExtAdminContext } from "../ExtAdmin";

const Wrapper = styled.div`
  & .ant-table-container {
    & .ant-table-filter-trigger {
      padding: 0px 20px;
    }

    font-size: 13px;
  }
`;

const MenuCellWrap = styled.div`
  width: 40px;
  position: relative;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NameCellWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 140;
`;

const Description = styled.div`
  margin-bottom: 8px;

  .underline {
    text-decoration: underline;
    margin: 0;
    padding: 0;
    display: inline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
  }
`;

const ROLES = {
  admin: "Admin",
  member: "Member",
} as const;

const PLANS = {
  pro: "Pro plan",
  starter: "Starter plan",
  plus: "Starter plan",
} as const;

const ROLE_METADATA = [
  {
    value: "admin",
    label: "Admin",
    tooltip: "Access to admin tools, billing management, plus all regular member features",
  },
  {
    value: "member",
    label: "Member",
    tooltip: "Regular access to Coefficient's features",
  },
] as const;

export const DomainUsers = () => {
  const { domains_in_domain_family: domains_in_domain_family } = useSelector((state: AppState) => ({
    domains_in_domain_family: state.app.domains_in_domain_family,
  }));

  const { domain } = useContext(ExtAdminContext) as EAContext;

  const { apiClient: api, DEPRECATED_api } = useApiContext();

  const [sortedColumn, setSortedColumn] = useState<SortColumn>({
    columnKey: "name",
    value: SortDirection.ascend,
  });

  const [filters, setFilters] = useState<Array<{ columnKey: string; values: string[] }>>([]);

  const [page, setPage] = React.useState(0);

  const [domainUsersPageOffset, setDomainUsersPageOffset] = React.useState(0);

  const domainUsersPageSize = 100;

  const [showManageRole, setShowManageRole] = useState<boolean>(false);

  const [user, setUser] = useState<User | undefined>(undefined);

  const [role, setRole] = useState<string>(UserRoleType.Member);

  const history = useHistory();

  const setPageAndOffset = (page: number) => {
    setPage(page);
    setDomainUsersPageOffset(page * domainUsersPageSize);
  };

  const {
    data: dataDomainUsers,
    isLoading,
    refetch,
  } = useAdminDomainUsers({
    domain: domain === "all_domains" ? domains_in_domain_family.toString() : domain,
    pageSize: domainUsersPageSize,
    offset: domainUsersPageOffset,
    sortColumn: sortedColumn,
    filters: undefined,
  });

  const { data: hasOneAdmin, isLoading: allUsersLoading } = useQuery({
    queryKey: ["allusers", showManageRole],
    queryFn: async () => {
      const response = await fetchAdminDomainUsers(DEPRECATED_api, {
        pageSize: undefined,
        sortColumn: undefined,
        offset: 0,
      });

      const hasOneAdmin =
        response.domainUsers.filter((user: User) => user.user_role_type === "admin").length === 1;

      return hasOneAdmin;
    },
    enabled: showManageRole,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    onError: () => {
      message.error("Failed to fetch users");
    },
  });

  const domainUsers = dataDomainUsers?.domainUsers;

  const getFilterValues = (key: string) => {
    const filter = filters.find(f => f.columnKey === key);
    return filter ? filter["values"] : [];
  };

  const deleteFilter = (key: string) => setFilters(filters.filter(f => f.columnKey !== key));

  const dropDownIcon = columnKey => {
    if (sortedColumn.columnKey === columnKey) {
      if (sortedColumn.value === "ascend") {
        return <ArrowDownOutlined />;
      } else {
        return <ArrowUpOutlined />;
      }
    } else {
      return <CaretDownOutlined />;
    }
  };

  const { mutate: handleUpdateUser, isLoading: isUpdatingRole } = useMutation({
    mutationFn: async ({ user, role }: { user: User; role: string }) => {
      await api.updateUserMe({
        update_user_me_request: {
          user: {
            user_role_type: role as UserRoleType,
            user_id: user.user_id,
          },
        },
      });

      await refetch();
    },
    onSuccess: () => {
      message.success("Role updated successfully");
      setShowManageRole(false);
    },
    onError: (error: AxiosErrorWithMessage) => {
      message.error(error.response.data.message || "Failed to update role");
    },
  });

  const columns = [
    {
      title: KEY_LABEL["name"],
      dataIndex: "name",
      key: "name",
      showSorterTooltip: false,
      width: 200,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={domainUsers ?? []}
          dataKey="name"
          filters={filters}
          hideFilters={true}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),
      onFilter: (value, record) => record["name"] === value,
      filteredValue: getFilterValues("name"),
      filterIcon: () => dropDownIcon("name"),
      render: (name: string, record: User, index) => {
        return (
          <NameCellWrap style={{ gap: "16px", alignItems: "center" }}>
            <Avatar
              style={{
                backgroundColor: AVATAR_COLORS[index % AVATAR_COLORS.length],
                flexShrink: 0,
              }}
              iconSize="large"
            >
              {name
                .split(" ")
                .map(n => n.substring(0, 1).toUpperCase())
                .join("")}
            </Avatar>

            <Typography noWrap lineClamp={2} title={name}>
              {name}
            </Typography>
          </NameCellWrap>
        );
      },
    },
    {
      title: KEY_LABEL["email"],
      dataIndex: "email",
      key: "email",
      showSorterTooltip: false,
      width: 200,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={domainUsers ?? []}
          dataKey="email"
          filters={filters}
          setFilters={setFilters}
          hideFilters={true}
          setSortedColumn={setSortedColumn}
        />
      ),
      onFilter: (value, record) => record["email"] === value,
      filteredValue: getFilterValues("email"),
      filterIcon: () => dropDownIcon("email"),
    },
    {
      title: KEY_LABEL["user_role_type"],
      dataIndex: "user_role_type",
      key: "user_role_type",
      showSorterTooltip: false,
      width: 200,
      render: (name: string) => {
        return (
          <NameCellWrap>
            <Typography noWrap lineClamp={2} title={ROLES[name]}>
              {ROLES[name]}
            </Typography>
          </NameCellWrap>
        );
      },
    },
    {
      title: KEY_LABEL["plan_type"],
      dataIndex: "plan_type",
      key: "plan_type",
      showSorterTooltip: false,
      width: 200,
      render: (name, record) => {
        const plan_owner = record["plan_owner"];
        return (
          <>
            {name ? (
              <NameCellWrap>
                <Description>
                  <Button
                    type="link"
                    onClick={() =>
                      history.push(
                        `/dashboard/billing/subscription?subscription_id=${
                          record.subscription_id.split("=")[1]
                        }`
                      )
                    }
                  >
                    {PLANS[name]}
                  </Button>
                </Description>
              </NameCellWrap>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Date Created",
      dataIndex: "created_dt",
      key: "created_dt",
      showSorterTooltip: false,
      width: 200,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={domainUsers ?? []}
          dataKey="created_dt"
          filters={filters}
          hideFilters={true}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),
      onFilter: (value, record) => record["created_dt"] === value,
      filteredValue: getFilterValues("created_dt"),
      filterIcon: () => dropDownIcon("created_dt"),
      render: d => {
        if (d) {
          const date = moment(d);
          return date.format("MM/DD/YY hh:mmA");
        } else {
          return "-";
        }
      },
    },
    {
      title: "",
      showSorterTooltip: false,
      width: 480,
      render: (d: User) => {
        return (
          <MenuCellWrap>
            <UserActionMenu
              noBackground
              noPadding
              user={d}
              onUpdateRole={() => {
                setUser(d);
                setRole(d.user_role_type || UserRoleType.Member);
                setShowManageRole(true);
              }}
            />
          </MenuCellWrap>
        );
      },
    },
  ];

  const locale = {
    emptyText: isLoading ? <LoaderWithPerfTimings name="DomainUsers.locale" /> : "No Users",
  };

  const isLastAdminRoleChange = hasOneAdmin && user?.user_role_type === "admin";

  return (
    <Wrapper>
      {!!filters.length && <TableFilterTags filters={filters} deleteFilter={deleteFilter} />}
      <Table
        columns={columns}
        dataSource={domainUsers ? domainUsers.map(d => ({ ...d, key: d.email })) : []}
        loading={false}
        locale={locale}
        pagination={false}
        scroll={{ x: "auto", y: "auto" }}
      />
      <AdminTableFooter
        page={page}
        pageSize={domainUsersPageSize}
        onPageChange={page => setPageAndOffset(page)}
        data={(domainUsers ?? []).map(x => [x.email, true])}
        totalRowCount={dataDomainUsers?.totalRecords ?? 0}
      />
      {showManageRole && user !== undefined ? (
        <ConfirmBox
          visible={showManageRole}
          title="Manage role"
          showCancel={false}
          okText="Save"
          okButton={
            !allUsersLoading && (
              <Button type="primary" disabled={isUpdatingRole || isLastAdminRoleChange}>
                {isUpdatingRole ? "Saving..." : "Save"}
              </Button>
            )
          }
          onCancel={() => setShowManageRole(false)}
          onOk={() => {
            if (role !== "admin" && hasOneAdmin) {
              return;
            }

            handleUpdateUser({ user, role });
          }}
          width={480}
          closable={true}
        >
          <FlexColumn>
            {allUsersLoading && <LoaderWithPerfTimings name="DomainUsers.allUsers" />}
            {!allUsersLoading && isLastAdminRoleChange && (
              <Alert
                type="info"
                showIcon
                message="Your domain must have at least one Admin. Make another user an Admin before changing."
              />
            )}
            {!allUsersLoading && (
              <Radio.Group
                style={{ display: "flex", flexDirection: "column", gap: "16px" }}
                onChange={e => setRole(e.target.value)}
                value={role}
              >
                {ROLE_METADATA.map(item => (
                  <div
                    key={item.value}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "16px",
                      border: `1px solid ${item.value === role ? "#2563EB" : "#E5E5E5"}`,
                      borderRadius: "8px",
                      backgroundColor: item.value === role ? "#F8FAFF" : "#F5F5F5",
                    }}
                  >
                    <Radio
                      value={item.value}
                      disabled={isLastAdminRoleChange && item.value === "member"}
                    >
                      <div style={{ marginLeft: "8px" }}>
                        <div
                          style={{
                            fontWeight: 500,
                            color: item.value === role ? "#2563EB" : "inherit",
                          }}
                        >
                          <strong>{item.label}</strong>
                        </div>
                        <div style={{ color: "#6B7280", fontSize: "14px", marginTop: "4px" }}>
                          {item.tooltip}
                        </div>
                      </div>
                    </Radio>
                  </div>
                ))}
              </Radio.Group>
            )}
          </FlexColumn>
        </ConfirmBox>
      ) : null}
    </Wrapper>
  );
};
