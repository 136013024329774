import { SnowflakeAppInfo } from "@coeff/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  Alert,
  Button,
  ConfirmDeleteDataSourceAppInfoModal,
  Form,
  ImportTypeIcon,
  Input,
  LoaderWithPerfTimings,
  WheelIcon,
} from "../components";
import { COLORS } from "../constants";
import { useApiContext, useTrack } from "../utils";

import { Header } from "./Dashboard/components";

const Spacer = styled.div`
  height: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
`;

const Container = styled.div`
  padding: 25px 30px;
  max-width: 660px;
`;

const StyledHeader = styled(Header)`
  h1 {
    margin: 0 10px;
  }

  .helpGuide {
    display: flex;
    align-items: center;
    margin-left: auto !important;
  }
`;

const StyledAlert = styled(Alert)`
  line-height: 1.8em;

  code {
    white-space: nowrap;
    background-color: ${COLORS.black4};
    padding: 4px 6px;
  }
`;

const FormGroup = styled.div`
  label {
    font-weight: bold;
    margin: 20px 12px 8px 2px;
  }
  margin-bottom: 12px;
`;

const IPWhitelistContainer = styled.div`
  margin-top: 24px;
  background-color: ${COLORS.black4};
  padding: 15px 24px;
`;

const HelpGuide = () => {
  return (
    <div className="helpGuide">
      <WheelIcon></WheelIcon>
      <Button type="link" noPadding>
        <a href="https://coefficient.io/help-center/snowflake" target="_blank">
          Help Guide
        </a>
      </Button>
    </div>
  );
};

export const SnowflakeOAuthManageSettings = () => {
  const track = useTrack();

  const history = useHistory();

  const { accountId } = useParams<{ accountId: string }>();

  const searchParams = new URLSearchParams(location.search);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [_, contextHolder] = message.useMessage();

  const paths = history.location.pathname.split("/");

  const backUrl = paths.splice(0, paths.length - 1).join("/");

  const { apiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const {
    data: snowflakeAppInfo,
    isFetching: isFetchingAppInfo,
    error: errorFetchingAppInfo,
  } = useQuery([QueryCacheKey.SNOWFLAKE_APP_INFO, accountId], () => api.listSnowflakeAppInfo(), {
    select: data => data.data.apps_list.filter(app => app.account_id === accountId)[0],
  });

  const deleteAppInfoMutation = useMutation(
    (appInfo: SnowflakeAppInfo) =>
      api.deleteSnowflakeAppInfo({
        delete_snowflake_app_info_request: {
          client_id: appInfo.client_id ?? "",
          domain: appInfo.domain,
        },
      }),
    {
      onSuccess: () => {
        history.goBack();
        message.success("Snowflake OAuth configuration deleted");
        queryClient.invalidateQueries([QueryCacheKey.SNOWFLAKE_APP_INFO]);
        queryClient.invalidateQueries([QueryCacheKey.SNOWFLAKE_APP_INFO, accountId]);
      },
      onError: () => message.error("Something went wrong. Please try again."),
    }
  );

  const renderContent = (children: React.ReactNode) => {
    return (
      <Container>
        <Button
          type="link"
          style={{ marginBottom: "8px", marginLeft: "-12px" }}
          onClick={() => history.push(backUrl)}
        >
          Back
        </Button>
        <StyledHeader>
          <ImportTypeIcon type="snowflake" />
          <h1>Snowflake OAuth</h1>
          <HelpGuide />
        </StyledHeader>
        {contextHolder}
        {children}
      </Container>
    );
  };

  if (deleteAppInfoMutation.isLoading || isFetchingAppInfo) {
    return renderContent(<LoaderWithPerfTimings name="SnowflakeOAuthSettings" />);
  }

  if (errorFetchingAppInfo) {
    return renderContent(
      <StyledAlert
        type="error"
        showIcon
        message="Error loading Snowflake OAuth Settings"
        description="Please try refreshing this page, or contact support@coefficient.io"
      />
    );
  }

  if (!snowflakeAppInfo) {
    return renderContent(
      <StyledAlert
        type="error"
        showIcon
        message="Could not find Snowflake OAuth Settings for this account"
        description="It's possible that the settings have been deleted."
      />
    );
  }

  return renderContent(
    <Content>
      <Form style={{ marginTop: "40px" }}>
        <Spacer />
        <FormGroup>
          <label>Account</label>
          <Input disabled value={snowflakeAppInfo.account_id} />
        </FormGroup>
        <FormGroup>
          <label>Client ID</label>
          <Input disabled value={snowflakeAppInfo.client_id} />
        </FormGroup>
        <FormGroup>
          <label>Client Secret</label>
          <Input disabled type="password" value="___________" />
        </FormGroup>
        <Button onClick={() => setIsDeleteModalVisible(true)}>Delete</Button>
      </Form>
      <IPWhitelistContainer>
        <p>
          <strong>IP Whitelisting</strong>
        </p>
        <p>
          If your database is behind a firewall or private network, you need to whitelist{" "}
          <strong>all three</strong> of our IP addresses:
        </p>
        <strong>
          34.217.184.131
          <br />
          44.234.233.60
          <br />
          52.32.132.51
        </strong>
      </IPWhitelistContainer>
      <ConfirmDeleteDataSourceAppInfoModal
        open={isDeleteModalVisible}
        isLoading={deleteAppInfoMutation.isLoading}
        dataImportCount={snowflakeAppInfo.data_import_count}
        dataSourceCount={snowflakeAppInfo.data_source_count}
        onOk={() => {
          setIsDeleteModalVisible(false);
          deleteAppInfoMutation.mutate(snowflakeAppInfo);
        }}
        onCancel={() => setIsDeleteModalVisible(false)}
        dataSourceType="snowflake"
      />
    </Content>
  );
};
