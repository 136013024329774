import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ConfirmBox, FlexColumn, FlexRow, ShareCoefficientLargeIcon } from "../../../components";
import { TagsInput } from "../../../components/TagsInput";
import { AppState } from "../../../store";
import { BContext } from "../../../types";
import { isValidEmail, pluralize, useTrack } from "../../../utils";
import { BillingContext } from "../Billing";

const StyledTagsInput = styled(TagsInput)`
  flex-direction: column;
  padding: 6px 12px;

  input {
    flex: unset;
    width: 100%;
  }
`;

type Props = {
  open: boolean;
  onClose: () => void;
};

export const InviteUserToPlanModal: React.FC<Props> = ({ open, onClose }) => {
  const track = useTrack();

  const { userSubscription, updateSubscription, billingMetadata, usersInCurrentPlan, syncBilling } =
    useContext(BillingContext) as BContext;

  const { domains_in_domain_family } = useSelector((state: AppState) => ({
    domains_in_domain_family: state.app.domains_in_domain_family,
  }));

  const [inviteEmailList, setInviteEmailList] = useState<string[]>([]);

  const [inviteEmailBtnDisableFlag, setInviteEmailBtnDisableFlag] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [tagInputKey, setTagInputKey] = useState<number>(1);

  useEffect(() => {
    setIsLoading(false);
    setInviteEmailBtnDisableFlag(false);
    setInviteEmailList([]);
    if (open) {
      // hard reset the tags input to clear errors on open
      setTagInputKey(key => key + 1);
    }
  }, [open]);

  const subscriptionDetails = userSubscription?.subscription_details;
  const planType = subscriptionDetails?.plan_type || "";

  const currentPlan = billingMetadata?.plans.find(
    plan =>
      plan.plan_type === planType && plan.billing_interval === subscriptionDetails?.billing_interval
  );

  const inviteUsers = async () => {
    setIsLoading(true);

    const invite_emails = [
      ...(userSubscription?.invite_users ?? []).map(u => u.user?.email),
      ...inviteEmailList,
    ];
    await updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "reassignment",
      invite_user_emails: [...new Set(invite_emails)],
      selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id),
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
      subscription_id: userSubscription?.subscription_details?.subscription_id,
    });
    track("billing_manage_team_user_added", {
      event_from: "invite_user_popup",
      added_users: inviteEmailList.map(e => e.trim()),
    });

    onClose();
  };

  const updateSubscriptionOnServer = async body => {
    const response = await updateSubscription(body);
    if (!response.is_success) {
      message.error(response.error_msg || "Uh-oh. Something went wrong. Please try again");
    } else {
      message.success("Your subscription has been updated");
      syncBilling();
    }
  };

  const subscribedAdditionalUserSeats =
    (subscriptionDetails?.max_users ?? 0) -
    (userSubscription?.invite_users ?? []).length -
    usersInCurrentPlan.length;

  const handleEmailChange = (tagList: string[]) => {
    setInviteEmailList(tagList);

    const hasError = tagList.some(tag => validateDomainEmail(tag));

    setInviteEmailBtnDisableFlag(hasError);
  };

  const invitedUserIdSet = new Set(userSubscription?.invite_users?.map(u => u.user.user_id!));

  const validateDomainEmail = (email: string) => {
    if (!isValidEmail(email)) {
      return "Please provide a valid email.";
    }

    if (!domains_in_domain_family.includes(email.split("@")[1])) {
      return "Email doesn't belong to the same domain.";
    }

    if (invitedUserIdSet.has(email)) {
      return "This user has already been invited to your plan.";
    }

    return undefined;
  };

  return (
    <ConfirmBox
      title={
        <FlexRow>
          <ShareCoefficientLargeIcon />
          Invite User To Plan
        </FlexRow>
      }
      visible={open}
      okText={`Invite User${pluralize(inviteEmailList.length)}`}
      cancelText="Cancel"
      okButtonProps={{
        disabled: inviteEmailBtnDisableFlag || inviteEmailList.length === 0 || isLoading,
      }}
      onOk={async () => {
        await inviteUsers();
      }}
      onCancel={() => {
        onClose();
      }}
      confirmLoading={isLoading}
      width={500}
    >
      <FlexColumn>
        <StyledTagsInput
          key={tagInputKey}
          placeholder="Enter emails"
          maxErrorText={`You only have ${subscribedAdditionalUserSeats} additional seat in your plan.`}
          onChange={tags => handleEmailChange(tags)}
          validator={validateDomainEmail}
          tags={inviteEmailList}
          max={subscribedAdditionalUserSeats}
        />
      </FlexColumn>
    </ConfirmBox>
  );
};
