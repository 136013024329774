import { SubscriptionUpdateType } from "@coeff/api";
import { useMutation } from "@tanstack/react-query";
import { Form } from "antd";
import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";

import { Button, FlexColumn, FlexRow, message, Modal, Typography } from "../../../components";
import { TagsInput } from "../../../components/TagsInput";
import { BContext } from "../../../types";
import { sentryCapture } from "../../../utils";
import { BillingContext } from "../Billing";

export const AdditionalEmailModal: React.FC<{
  onCancel: () => void;
}> = ({ onCancel }) => {
  const { userSubscription, updateSubscription, syncBilling } = useContext(
    BillingContext
  ) as BContext;

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      additionalEmails:
        userSubscription?.subscription_details?.additional_billing_contacts?.join() || "",
    },
  });

  const { mutate: updateBillingContacts, isLoading } = useMutation({
    mutationFn: async (data: { additionalEmails: string }) => {
      const updatedInfo = await updateSubscription({
        subscription_id: userSubscription?.subscription_details?.subscription_id,
        update_type: SubscriptionUpdateType.UpdateAdditionalBillingContacts,
        additional_billing_contacts: data.additionalEmails
          .split(",")
          .map(email => email.trim())
          .filter(Boolean),
      });

      if (updatedInfo.is_success) {
        await syncBilling();
      }

      return updatedInfo;
    },
    onSuccess: response => {
      if (response.is_success) {
        message.success("Additional billing contacts updated successfully");
        onCancel();
        reset();
      } else {
        message.error(`Failed to update additional billing contacts: ${response.error_msg}`);
      }
    },
    onError: (error: Error) => {
      sentryCapture({ error, name: "UpdateAdditionalBillingContacts" });
      message.error(`Failed to update additional billing contacts: ${error.message}`);
    },
  });

  return (
    <Modal visible onCancel={onCancel} width={600}>
      <Typography fontWeight={"bold"} fontSize="22px">
        Billing contacts
      </Typography>
      <Typography color="textSecondary">
        These will get included on billing related emails
      </Typography>
      <Form
        layout="vertical"
        style={{ padding: "16px 0" }}
        onFinish={handleSubmit(data => updateBillingContacts(data))}
      >
        <FlexColumn gap={0}>
          <Typography>Additional billing contacts (optional)</Typography>
          <Controller
            name="additionalEmails"
            control={control}
            rules={{
              validate: (value: string) => {
                if (!value) {
                  return true;
                }
                const emails = value.split(",");
                return (
                  emails.every(email =>
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email.trim())
                  ) || "Invalid email format"
                );
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TagsInput
                tags={value ? value.split(",").filter(Boolean) : []}
                onChange={tags => onChange(tags.join(","))}
                placeholder="Enter email addresses"
                aria-label="Additional billing email addresses"
                error={!!error}
                maxErrorText={error?.message}
                validator={email =>
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
                    ? undefined
                    : "Invalid email format"
                }
              />
            )}
          />
          <Typography color="textSecondary" fontSize="12px">
            Plan managers are included automatically
          </Typography>
        </FlexColumn>
      </Form>
      <FlexRow style={{ justifyContent: "flex-end" }}>
        <Button onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(data => updateBillingContacts(data))}
          disabled={isLoading}
        >
          {isLoading ? "Saving..." : "Save"}
        </Button>
      </FlexRow>
    </Modal>
  );
};
